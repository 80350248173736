<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="排放污水厂" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input placeholder="请输入污水处理厂" :rows="4" v-decorator="['destination']"></a-input>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { carLoopRecordFormVO } from './common/carLoopRecordFormVO'
  import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'CarLoopRecordForm',
    mixins: [entityCURDCallback],
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data() {
      return {
        entityBaseUrl: SERVICE_URLS.car.carLoopRecordApi,
        initFormItem: carLoopRecordFormVO(),
        showFormItem: false,
        userTreeData: [],
        carList: [],
        dateSearch: null, //日期绑定数据
        labelCol: { span: 5 },
        wrapperCol: { span: 18 }
      }
    },
    methods: {
      setFields(values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          destination: this.formItem.destination
        })
      },
      setVOFields(values) {
        this.formItem.destination = values.destination
      }
    }
  }
</script>

<style scoped></style>
